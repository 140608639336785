<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm3 align-self-center>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >View Booking</span
        >
      </v-flex>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <!-- <v-flex xs12 sm3 align-self-center pb-1 class="text-right">
        <v-btn
          @click="generateReceipt()"
          
          color="#ff6200" dark
          >Download Reservation</v-btn
        >
      </v-flex> -->
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card class="pa-4">
          <v-layout wrap>
            <v-flex xs12>
              <v-layout wrap mt-2 v-if="reservationList">
                <!-- <v-flex
                  xs12
                  px-0
                  px-md-2
                 ><span  style="
                    font-family: LexendFont;
                    font-weight: 500;
                    font-size: 22px;
                  "
                  >OverView </span></v-flex
                > -->
                <v-flex xs12 py-2>
                  <v-layout wrap>
                    <v-flex
                      xs12
                      sm3
                      pb-1
                      align-self-center
                      text-left
                      px-2
                      v-if="reservationList.reservationNumber"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Reservation No : </span
                      ><br />
                      <span
                        v-if="reservationList.reservationNumber"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.reservationNumber }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      pb-1
                      align-self-center
                      text-left
                      px-2
                      v-if="reservationList.reservationNumber"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Booking Type : </span
                      ><br />
                      <span
                        v-if="reservationList.bookingType"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.bookingType }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Date Of Booking :</span
                      >
                      <br />
                      <span
                        v-if="reservationList.bookingDate"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.bookingDate }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Guest Name :</span
                      ><br />
                      <span
                        v-if="reservationList.guestName"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.guestName }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Booking Mode : </span
                      ><br />
                      <span
                        v-if="reservationList.bookingMode"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.bookingMode }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      pb-1
                      align-self-center
                      text-left
                      px-2
                      v-if="reservationList.companyDetails"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Company Name : </span
                      ><br />
                      <span
                        v-if="reservationList.companyDetails.companyName"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.companyDetails.companyName }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      pb-1
                      align-self-center
                      text-left
                      px-2
                      v-if="reservationList.companyDetails"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >GST :</span
                      ><br />
                      <span
                        v-if="reservationList.companyDetails.companyGST"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.companyDetails.companyGST }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <!-- <v-flex
                      xs12
                      sm3
                      pb-1
                      align-self-center
                      text-left
                      px-2
                      v-if="reservationList.companyDetails"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >State :</span
                      ><br />
                      <span
                        v-if="reservationList.companyDetails.state"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.companyDetails.state }}</span
                      >
                      <span v-else>-</span>
                    </v-flex> -->
                    <v-flex
                      xs12
                      sm3
                      pb-1
                      align-self-center
                      text-left
                      px-2
                      v-if="reservationList.companyDetails"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Country :</span
                      ><br />
                      <span
                        v-if="reservationList.companyDetails.country"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.companyDetails.country }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm9
                      pb-1
                      align-self-center
                      text-left
                      px-2
                      v-if="reservationList.companyDetails"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Address :</span
                      ><br />
                      <span
                        v-if="reservationList.companyDetails.companyAddress"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{
                          reservationList.companyDetails.companyAddress
                        }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Payment Mode :</span
                      ><br />
                      <span
                        v-if="reservationList.paymentMode"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.paymentMode }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex
                      xs12
                      align-self-center
                      text-left
                      px-2
                      pb-1
                      v-if="reservationList.email"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Contacts :</span
                      >
                      <v-layout wrap v-if="reservationList.email.length > 0">
                        <v-flex
                          xs12
                          sm3
                          v-for="(item2, i) in reservationList.email"
                          :key="i"
                        >
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 300;
                              font-size: 16px;
                              color: black;
                            "
                          >
                            {{ item2 }}</span
                          >
                        </v-flex>
                        <v-flex
                          xs12
                          sm4
                          v-for="(item2, i) in reservationList.phone"
                          :key="i"
                        >
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 300;
                              font-size: 16px;
                              color: black;
                            "
                          >
                            {{ item2 }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm10 text-left v-if="reservationList.bankDetails"
              ><v-divider></v-divider
            ></v-flex>
            <v-flex xs12>
              <v-layout wrap mt-2 v-if="reservationList.bankDetails">
                <v-flex xs12 px-0 px-md-2
                  ><span
                    style="
                      font-family: LexendFont;
                      font-weight: 500;
                      font-size: 22px;
                    "
                    >Bank Details</span
                  ></v-flex
                >
                <v-flex xs12 py-2>
                  <v-layout wrap>
                    <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Bank Name : </span
                      ><br />
                      <span
                        v-if="reservationList.bankDetails.bankName"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.bankDetails.bankName }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Branch : </span
                      ><br />
                      <span
                        v-if="reservationList.bankDetails.branchName"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.bankDetails.branchName }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >IFSC :</span
                      ><br />
                      <span
                        v-if="reservationList.bankDetails.ifscCode"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.bankDetails.ifscCode }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Account Name: </span
                      ><br />
                      <span
                        v-if="reservationList.bankDetails.accountName"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.bankDetails.accountName }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Account No : </span
                      ><br />
                      <span
                        v-if="reservationList.bankDetails.accountNumber"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.bankDetails.accountNumber }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm6 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Booking Policy: </span
                      ><br />
                      <span
                        v-if="reservationList.bankDetails.bookingPolicy"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.bankDetails.bookingPolicy }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm6 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Cancellation Policy : </span
                      ><br />
                      <span
                        v-if="reservationList.bankDetails.cancellationPolicy"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{
                          reservationList.bankDetails.cancellationPolicy
                        }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm10 text-left><v-divider></v-divider></v-flex>

            <v-flex xs12>
              <v-layout wrap mt-2 v-if="bookingItems">
                <!-- <v-flex xs12 align-self-center>
                  <v-simple-table class="outlined-table">
                    <thead>
                      <tr>
                        <th>Room Type</th>
                        <th style="width: 17%">
                          <v-card
                            width="110px"
                            elevation="0"
                            color="transparent"
                            ><span style="color: #626270">Arrival</span></v-card
                          >
                        </th>
                        <th style="width: 120px !important">
                          <v-card
                            width="110px"
                            elevation="0"
                            color="transparent"
                            ><span style="color: #626270"
                              >Check Out</span
                            ></v-card
                          >
                        </th>
                        <th>
                          Total <br />
                          Rooms
                        </th>
                        <th>Night</th>
                        <th>Meal Plan</th>
                        <th>Total Adult/ Room</th>
                        <th>Room Amt / Night</th>

                        <th>Extra Adult With Bed</th>
                        <th>Rate</th>
                        <th>Extra Adult Without Bed</th>
                        <th>Rate</th>
                        <th>Child With Matters</th>
                        <th>Rate</th>
                        <th>Child Without Matters</th>
                        <th>Rate</th>
                        <th>Total Pax</th>
                        <th>Extra Bed Total Amt.</th>
                        <th>Total Amt</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in bookingItems" :key="index">
                        <td>{{ row.roomId.roomCategory.category_name }}</td>
                        <td>{{ formatDate(row.checkInDate) }}</td>
                        <td>{{ formatDate(row.checkOutDate) }}</td>
                        <td>{{ row.totalRooms }}</td>
                        <td>{{ row.nights }}</td>
                        <td>{{ row.plan }}</td>
                        <td>{{ row.guestInfo.adultGuest }}</td>
                        <td>{{ row.guestInfo.amountPerNight }}</td>

                        <td>{{ row.guestInfo.extraAdultWithBed }}</td>
                        <td>{{ row.guestInfo.extraAdultRateWithBed }}</td>
                        <td>{{ row.guestInfo.extraAdultWithoutBed }}</td>
                        <td>{{ row.guestInfo.extraAdultRateWithoutBed }}</td>
                        <td>{{ row.guestInfo.extraChildWithBed }}</td>
                        <td>{{ row.guestInfo.extraChildRateWithBed }}</td>
                        <td>{{ row.guestInfo.extraChildWithoutBed }}</td>
                        <td>{{ row.guestInfo.extraChildRateWithoutBed }}</td>
                        <td>{{ row.totalPax }}</td>
                        <td>{{ row.extraBedTotalAmt }}</td>
                        <td>{{ row.grandTotal }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-flex> -->
                <v-flex xs12 align-self-center>
                  <v-simple-table class="outlined-table">
                    <thead>
                      <tr>
                        <th>Room Type</th>
                        <th style="width: 17%">
                          <v-card
                            width="110px"
                            elevation="0"
                            color="transparent"
                          >
                            <span style="color: #626270">Arrival</span>
                          </v-card>
                        </th>
                        <th style="width: 120px !important">
                          <v-card
                            width="110px"
                            elevation="0"
                            color="transparent"
                          >
                            <span style="color: #626270">Check Out</span>
                          </v-card>
                        </th>
                        <th>Total <br />Rooms</th>
                        <th>Night</th>
                        <th>Meal Plan</th>
                        <th>Total Adult/Room</th>
                        <th>Room Amt/Night</th>
                        <th>Extra Adult With Bed</th>
                        <th>Rate</th>
                        <th>Extra Adult Without Bed</th>
                        <th>Rate</th>
                        <th>Child With Matters</th>
                        <th>Rate</th>
                        <th>Child Without Matters</th>
                        <th>Rate</th>
                        <th>Total Pax</th>
                        <th>Extra Bed Total Amt.</th>
                        <th>Total Amt</th>
                        <!-- New column for Room Allocation -->
                        <th>Room Allocations</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in bookingItems" :key="index">
                        <td>{{ row.roomId.roomCategory.category_name }}</td>
                        <td>{{ formatDate(row.checkInDate) }}</td>
                        <td>{{ formatDate(row.checkOutDate) }}</td>
                        <td>{{ row.totalRooms }}</td>
                        <td>{{ row.guestInfo.numberOfNights }}</td>
                        <td>{{ row.plan }}</td>
                        <td>{{ row.guestInfo.adultGuest }}</td>
                        <td>{{ row.guestInfo.amountPerNight }}</td>
                        <td>{{ row.guestInfo.extraAdultWithBed }}</td>
                        <td>{{ row.guestInfo.extraAdultRateWithBed }}</td>
                        <td>{{ row.guestInfo.extraAdultWithoutBed }}</td>
                        <td>{{ row.guestInfo.extraAdultRateWithoutBed }}</td>
                        <td>{{ row.guestInfo.extraChildWithBed }}</td>
                        <td>{{ row.guestInfo.extraChildRateWithBed }}</td>
                        <td>{{ row.guestInfo.extraChildWithoutBed }}</td>
                        <td>{{ row.guestInfo.extraChildRateWithoutBed }}</td>
                        <td>{{ row.guestInfo.totalPax }}</td>
                        <td>{{ row.guestInfo.extraBedTotalAmt }}</td>
                        <td>{{ row.guestInfo.totalAmount }}</td>

                        <!-- Room Allocations Column -->
                        <td>
                          <!-- Iterating over roomAllocationInfo -->
                          <v-card
                            v-for="allocation in row.roomAllocationInfo"
                            :key="allocation._id"
                            elevation="0"
                            width="100px"
                            color="transparent"
                          >
                            <v-layout wrap>
                              <v-flex xs12>{{
                                formatDate(allocation.date)
                              }}</v-flex>
                              <v-flex
                                xs12
                                v-for="room in allocation.roomAllocations
                                  .roomDetails"
                                :key="room._id"
                                >{{ room.roomNumber }}</v-flex
                              >
                            </v-layout>
                          </v-card>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 text-left><v-divider></v-divider></v-flex>
            <v-flex xs12>
              <v-layout wrap pt-2>
                <v-flex
                  xs12
                  sm3
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="reservationList.companyDetails"
                >
                  <span
                    style="
                      font-family: LexendFont;
                      font-weight: 500;
                      font-size: 18px;
                    "
                    >Billing Mode : </span
                  ><br />
                  <span
                    v-if="reservationList.billingMode"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    {{ reservationList.billingMode }}</span
                  >
                  <span v-else>-</span>
                </v-flex>
                <v-flex
                  xs12
                  sm2
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="reservationList.taxAndGstSplitUp"
                >
                  <span
                    style="
                      font-family: LexendFont;
                      font-weight: 500;
                      font-size: 18px;
                    "
                    >GST : </span
                  ><br />
                  <span
                    v-if="reservationList.taxAndGstSplitUp.gstAmount"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    {{ reservationList.taxAndGstSplitUp.gstAmount }}</span
                  >
                  <span v-else>0</span>
                </v-flex>
                <v-flex
                  xs12
                  sm2
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="reservationList.taxAndGstSplitUp"
                >
                  <span
                    style="
                      font-family: LexendFont;
                      font-weight: 500;
                      font-size: 18px;
                    "
                    >IGST : </span
                  ><br />
                  <span
                    v-if="reservationList.taxAndGstSplitUp.igst"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    {{ reservationList.taxAndGstSplitUp.igst }}</span
                  >
                  <span v-else>0</span>
                </v-flex>
                <v-flex
                  xs12
                  sm2
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="reservationList.taxAndGstSplitUp"
                >
                  <span
                    style="
                      font-family: LexendFont;
                      font-weight: 500;
                      font-size: 18px;
                    "
                    >SGST : </span
                  ><br />
                  <span
                    v-if="reservationList.taxAndGstSplitUp.sgst"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    {{ reservationList.taxAndGstSplitUp.sgst }}</span
                  >
                  <span v-else>0</span>
                </v-flex>
                <v-flex
                  xs12
                  sm2
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="reservationList.taxAndGstSplitUp"
                >
                  <span
                    style="
                      font-family: LexendFont;
                      font-weight: 500;
                      font-size: 18px;
                    "
                    >CGST : </span
                  ><br />
                  <span
                    v-if="reservationList.taxAndGstSplitUp.cgst"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    {{ reservationList.taxAndGstSplitUp.cgst }}</span
                  >
                  <span v-else>0</span>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm6 lg4>
                  <v-layout wrap px-2>
                    <v-flex xs12 pt-2>
                      <span class="title2">Total Amt :</span>
                      <span
                        v-if="reservationList.totalAmount"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.totalAmount }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <span class="title2">Advance Amt :</span>
                      <span
                        v-if="reservationList.advancePayment"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.advancePayment }}</span
                      >
                      <span v-else>0</span>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <span class="title2">Balance Amt :</span>
                      <span
                        v-if="reservationList.balancePayment"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.balancePayment }}</span
                      >
                      <span v-else>0</span>
                    </v-flex>
                    <v-flex xs12 pt-2 v-if="reservationList.balancePayment < 1">
                      <span class="title2">Balance payed by :</span>
                      <span
                        v-if="reservationList.balancePaidBy"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.balancePaidBy }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <span class="title2">Payment Status :</span>
                      <span
                        v-if="reservationList.paymentStatus"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ reservationList.paymentStatus }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 sm6 lg4 pt-2 text-right align-self-end>
                  <span class="title2">Cut Off Date</span><br />
                  <span
                    v-if="reservationList.cutOffDate"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    {{ formatDate(reservationList.cutOffDate) }}</span
                  >
                  <span v-else>-</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12></v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      dialog: false,
      reservationList: [],
      bookingItems: [],
      rules: {
        phone: [
          (v) => !!v || "Phone number is required",
          (v) => /^\d+$/.test(v) || "Phone number must be valid",
        ],
        email: [
          (v) => !!v || "Email is required",
          (v) => /.+@.+/.test(v) || "Email must be valid",
        ],
      },
    };
  },

  mounted() {
    this.getBooking();
  },
  methods: {
    generateReceipt() {
      this.appLoading = true;

      axios({
        method: "get",
        url: "/generate/recipt",
        responseType: "blob", // Ensure the response is handled as a blob
        params: {
          bookingId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "receipt.pdf"); // Set the default file name
          document.body.appendChild(link);
          link.click(); // Programmatically click the link to trigger the download
          document.body.removeChild(link); // Clean up the link element
        })
        .catch((error) => {
          console.error("Error downloading the PDF:", error);
        })
        .finally(() => {
          this.appLoading = false;
        });
    },
    getBooking() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/booking/details",
        data: {
          bookingId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.reservationList = response.data.data;
          this.bookingItems = response.data.bookingItems;
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
  
<style>
.outlined-table {
  border: 1px solid grey;
  border-collapse: collapse;
}

.outlined-table th,
.outlined-table td {
  border: 1px solid grey;
  padding: 8px;
  text-align: left;
}

.outlined-table th {
  background-color: #f5f5f5;
}

.arrival-date {
  width: 120px !important;
}

.checkout-date {
  width: 120px !important;
}
</style>
  





